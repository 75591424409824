import { onMounted, onUnmounted, Ref } from 'vue';

export function useClickOutside(el: Ref<HTMLElement | null>, cb: () => void) {
  const handleClickOutside = (event: MouseEvent) => {
    if (el.value && !el.value.contains(event.target as Node)) {
      cb();
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
}
