<!-- src/components/Toast.vue -->
<template>
  <ToastProvider>
    <ToastViewport
      class="fixed bottom-0 right-0 z-50 m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-2 p-6 outline-none"
    />
    <transition
      enter-active-class="transition duration-500 ease-out"
      enter-from-class="transform translate-y-4 opacity-0 scale-95"
      enter-to-class="transform translate-y-0 opacity-100 scale-100"
      leave-active-class="transition duration-300 ease-in"
      leave-from-class="transform translate-y-0 opacity-100 scale-100"
      leave-to-class="transform translate-y-4 opacity-0 scale-95"
    >
      <ToastRoot
        v-if="isOpen"
        :class="[
          'rounded-lg bg-gradient-to-r from-blue-600 to-blue-700 p-4 shadow-lg border border-blue-400',
          'transform transition-all duration-500',
          'hover:shadow-xl hover:translate-y-[-2px]',
        ]"
        :duration="duration"
        :actionHandler="actionHandler"
      >
        <div class="flex items-center justify-between gap-3">
          <div class="flex items-center gap-3">
            <div class="flex size-8 shrink-0 items-center justify-center rounded-full bg-white/10">
              <InformationCircleIcon class="size-5 text-white" />
            </div>
            <ToastDescription class="text-sm font-medium text-white">
              {{ text }}
            </ToastDescription>
          </div>
          <ToastClose 
            class="rounded-full p-1.5 text-white/70 hover:bg-white/10 hover:text-white transition-colors" 
            @click="close"
          >
            <XMarkIcon class="size-4" />
          </ToastClose>
        </div>
        <button
          v-if="actionLabel"
          @click="action"
          class="mt-3 w-full rounded-lg bg-white/10 px-3 py-1.5 text-sm font-semibold text-white hover:bg-white/20 transition-colors"
        >
          {{ actionLabel }}
        </button>
      </ToastRoot>
    </transition>
  </ToastProvider>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import {
  ToastProvider,
  ToastRoot,
  ToastViewport,
  ToastClose,
  ToastDescription,
} from 'radix-vue';
import { 
  XMarkIcon,
  InformationCircleIcon 
} from '@heroicons/vue/24/outline';

const props = defineProps<{
  text: string;
  actionLabel?: string;
  duration?: number;
  visible: boolean;
  actionHandler?: () => void;
}>();

const emit = defineEmits<{
  (e: 'action'): void;
  (e: 'close'): void;
}>();

const isOpen = ref(false);

watch(
  () => props.visible,
  (newValue) => {
    isOpen.value = newValue;
  }
);

const close = () => {
  isOpen.value = false;
  emit('close');
};

const action = () => {
  if (props.actionHandler) {
    props.actionHandler();
  }
  emit('action');
  close();
};
</script>

<style>
.shadow-lg {
  --tw-shadow: 0 10px 30px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 40px -3px rgba(0, 0, 0, 0.1), 0 8px 12px -4px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-shadow);
}
</style>
