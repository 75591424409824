<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class="h-full">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-600 px-6 pb-2"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <LogoIconFactory />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li
                          v-for="item in navigation"
                          :key="item.name"
                          class="cursor-pointer"
                        >
                          <a
                            @click="changeRoute(item.routeName)"
                            :class="[
                              item.current
                                ? 'bg-gray-50 text-SonicSpecBrandColor'
                                : 'text-[#fcffff] hover:bg-gray-50 hover:text-SonicSpecBrandColor',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                item.current
                                  ? 'text-SonicSpecBrandColor'
                                  : 'text-blue-300 group-hover:text-SonicSpecBrandColor',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div
                        v-if="teams?.length"
                        class="text-xs font-semibold leading-6 text-gray-400"
                      >
                        Your teams
                      </div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a
                            :href="team.href"
                            :class="[
                              team.current
                                ? 'bg-gray-50 text-SonicSpecBrandColor'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-SonicSpecBrandColor',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            <span
                              :class="[
                                team.current
                                  ? 'border-SonicSpecBrandColor text-SonicSpecBrandColor'
                                  : 'border-gray-200 text-gray-400 group-hover:border-SonicSpecBrandColor group-hover:text-SonicSpecBrandColor',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-pureWhite text-[0.625rem] font-medium',
                              ]"
                              >{{ team.initial }}</span
                            >
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-blue-600 px-6"
      >
        <div class="flex h-16 shrink-0 items-center">
          <LogoIconFactory />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li
                  v-for="item in navigation"
                  :key="item.name"
                  class="cursor-pointer"
                >
                  <a
                    @click="changeRoute(item.routeName)"
                    :class="[
                      item.current
                        ? 'bg-gray-50 text-SonicSpecBrandColor'
                        : 'text-[#fcffff] hover:bg-gray-50 hover:text-SonicSpecBrandColor',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                          ? 'text-SonicSpecBrandColor'
                          : 'text-blue-300 group-hover:text-SonicSpecBrandColor',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <div
                class="text-xs font-semibold leading-6 text-gray-400"
                v-if="teams?.length"
              >
                Your teams
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="team in teams" :key="team.name">
                  <a
                    :href="team.href"
                    :class="[
                      team.current
                        ? 'bg-gray-50 text-SonicSpecBrandColor'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-SonicSpecBrandColor',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    ]"
                  >
                    <span
                      :class="[
                        team.current
                          ? 'border-SonicSpecBrandColor text-SonicSpecBrandColor'
                          : 'border-gray-200 text-gray-400 group-hover:border-SonicSpecBrandColor group-hover:text-SonicSpecBrandColor',
                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-pureWhite text-[0.625rem] font-medium',
                      ]"
                      >{{ team.initial }}</span
                    >
                    <span class="truncate">{{ team.name }}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="group -mx-6 mt-auto">
              <a
                target="_blank"
                href="https://intercom.help/sonicspec/en/"
                class="flex items-center gap-x-3 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
              >
                <QuestionMarkCircleIcon
                  class="h-6 w-6 text-[#FFFFFF] group-hover:text-SonicSpecBrandColor"
                ></QuestionMarkCircleIcon>
                <span class="sr-only">Help Center</span>
                <span
                  aria-hidden="true"
                  class="font-semibold text-[#fcffff] group-hover:text-SonicSpecBrandColor"
                  >Help Center</span
                >
                <ArrowTopRightOnSquareIcon
                  class="hidden h-4 text-gray-400 group-hover:block group-hover:text-SonicSpecBrandColor"
                ></ArrowTopRightOnSquareIcon>
              </a>
            </li>
            <li class="-mx-6 group">
              <DownloadAppButton />
            </li>
            <li class="-mx-6 group">
              <router-link
                to="/profile"
                class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-blue-200 hover:bg-gray-50 hover:text-SonicSpecBrandColor"
              >
                <span
                  class="border-gray-200 text-[#475467] group-hover:border-blue-100 group-hover:text-SonicSpecBrandColor
                    flex h-10 w-10 shrink-0 items-center justify-center rounded-full border bg-pureWhite text-[1rem] font-semibold"
                  >{{ props.userName.charAt(0).toUpperCase() }}</span
                >
                <span class="sr-only">Your profile</span>
                <section class="flex flex-col gap-[0px]">
                  <span aria-hidden="true" class=" text-[#FFFFFF] font-semibold group-hover:text-SonicSpecBrandColor truncate max-w-[10em]">{{ props.userName }}</span>
                  <span v-if="email" aria-hidden="true" class="text-[#fcffff] group-hover:text-SonicSpecBrandColor truncate max-w-[10em]">{{ email }}</span>
                </section>
                <div @click.prevent.stop="logout" class="hover:border-[#84CAFF] hover:border rounded-md p-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#84CAFF" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                  </svg>
                </div>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center gap-x-6 bg-pureWhite px-4 py-4 shadow-sm sm:px-6 lg:hidden"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">
        Dashboard
      </div>
      <a href="#">
        <span class="sr-only">Your profile</span>
        <span
          :class="[
            false
              ? 'border-SonicSpecBrandColor text-SonicSpecBrandColor'
              : 'border-gray-200 text-[#475467] group-hover:border-SonicSpecBrandColor group-hover:text-SonicSpecBrandColor',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-pureWhite text-[0.625rem] font-medium',
          ]"
          >{{ props.userName.charAt(0).toUpperCase() }}</span
        >
      </a>
    </div>

    <main class="flex min-h-full flex-col bg-pureWhite lg:pl-72">
      <div class="grow h-full px-4 sm:px-6 lg:px-8">
        <!-- Your content -->
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3Icon,
  FolderIcon,
  QuestionMarkCircleIcon,
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
  BookOpenIcon,
  TagIcon,
  CodeBracketSquareIcon,
  CodeBracketIcon,
} from '@heroicons/vue/24/outline';
import IconSpecifiers from '@/components/icons/IconSpecifiers.vue';
import LogoIconFactory from '@/components/icons/LogoIconFactory.vue';
import StackShare from '@/components/icons/stackShared.vue';
import { resetAnalytics, trackEvent } from '@/services/analytics.service';
import { stytch } from '@/stytch';
import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';
import DownloadAppButton from '@/components/tailwindCSS/DownloadAppButton.vue';

const router = useRouter();
const authStore = useAuthStore();
const props = defineProps({
  currRoute: {
    type: String,
    default: '',
  },
  userName: {
    type: String,
    default: sessionStorage.getItem('username') || 'SonicSpec',
  },
});

const email = sessionStorage.getItem('email');
function createNavItem(name, routeName, icon) {
  return {
    name,
    routeName,
    icon,
    get current() {
      return props.currRoute === this.routeName;
    },
  };
}


const navigation = computed(() => {
  const navigationItems = [
    createNavItem('Projects', 'Project Viewer', FolderIcon),
    createNavItem('Shared Projects', 'Shared Projects Viewer', StackShare),
    createNavItem('Library', 'Library Viewer', BookOpenIcon),
    createNavItem('Tags', 'Tags Viewer', TagIcon),
    createNavItem('Product Lines', 'Product Lines', CodeBracketSquareIcon),
    createNavItem('Product Types', 'Product Types', CodeBracketIcon),
    createNavItem('Specifiers', 'Specifier Viewer', IconSpecifiers),
  ]

  if (email && email.endsWith('@sonicspec.com')) {
    navigationItems.push(
      createNavItem('Discover Organizations', 'Discover Organization Viewer', IconSpecifiers)
    )
  }

  return navigationItems;
})

// const teams = [
//   { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
//   { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
//   { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
// ];

const teams = [];

function changeRoute(routeName) {
  router.push({ name: routeName });
  trackEvent('Navigation Change', {
    routeName,
  });
}

const sidebarOpen = ref(false);

function logout() {
    authStore.logout(router);
}
</script>
