<!-- ErrorBoundary.vue -->
<template>
  <slot v-if="!error"></slot>
  <div v-else class="flex h-full items-center justify-center bg-gray-100">
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Oops! Something went wrong.
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          We're sorry for the inconvenience. Please try refreshing the page or
          contact support if the problem persists.
        </p>
      </div>
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg
              class="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">Error Details</h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          @click="reload"
          class="group btn-primary relative flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white"
        >
          Refresh Page
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue';

const error = ref<string | null>(null);

onErrorCaptured((err: Error, instance: unknown, info: string) => {
  error.value = `${err.toString()}\nInfo: ${info}`;
  console.error(err);
  return false; // Prevent the error from propagating further
});

const reload = (): void => {
  window.location.reload();
};
</script>
