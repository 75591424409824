import mixpanel from 'mixpanel-browser';

const MixpanelPlugin = {
  install: (app, options) => {
    mixpanel.init(options.token, {ignore_dnt: true, debug: process.env.NODE_ENV === 'development'});
    app.config.globalProperties.$mixpanel = mixpanel;
  },
};

export default MixpanelPlugin;
