// src/plugins/toast.ts
import { App, inject } from 'vue';
import { createToastSystem, ToastSystem } from '../composables/useToast';

const ToastSymbol = Symbol();

export const ToastPlugin = {
  install(app: App) {
    const toastSystem = createToastSystem();
    app.provide(ToastSymbol, toastSystem);
    app.config.globalProperties.$toast1 = toastSystem.showToast;
  },
};

export function useToast(): ToastSystem {
  const toastSystem = inject<ToastSystem>(ToastSymbol);
  if (!toastSystem) throw new Error('Toast system not provided');
  return toastSystem;
}
