import { createApp } from 'vue';
import { createPinia } from 'pinia';
import MixpanelPlugin from '@/plugins/mixpanel';
import './scripts/axiosConfig';
import App from './App.vue';
import router from './router';
import * as Sentry from '@sentry/vue';
import FeedbackButton from '@/components/FeedbackButton.vue';
import { io } from 'socket.io-client';
import axios from 'axios';
import {
  trackEvent,
  identifyUser,
  setUserProperties,
} from './services/analytics.service.ts';
import { ToastPlugin as ToastPluginRadix } from './plugins/toast';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { useAuthStore } from '@/stores/authStore';
import { socket } from '@/services/socket.service';

import './assets/main.css';
const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;

const app = createApp(App);

app.directive('focus', {
  mounted: (el, binding) => {
    if (!binding.value) return
    el.focus()
  }
})

app.directive('click-outside', {
  mounted: function (element, binding) {
    element.clickOutsideEvent = function (event) {
      const excludeComponent = document.getElementById(binding.arg)

      if (
        !(element == event.target || element.contains(event.target)) &&
        !(
          excludeComponent &&
          (event.target == excludeComponent || excludeComponent.contains(event.target))
        )
      ) {
        binding.value(event, element)
      }
    }
    document.addEventListener('click', element.clickOutsideEvent)
  },
  unmounted: function (element) {
    document.removeEventListener('click', element.clickOutsideEvent)
  }
});

app.directive('visible', {
  mounted: function (element, binding) {
    // Set the initial visibility based on the binding value
    element.style.visibility = binding.value ? 'visible' : 'hidden';
  },
  updated: function (element, binding) {
    // Update the visibility when the bound value changes
    element.style.visibility = binding.value ? 'visible' : 'hidden';
  }
});

app.config.globalProperties.$socket = socket;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/app\.sonicspec\.com/,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.component('FeedbackButton', FeedbackButton);
app.use(MixpanelPlugin, { token: mixpanelToken });
app.config.globalProperties.$trackEvent = trackEvent;
app.config.globalProperties.$identifyUser = identifyUser;
app.config.globalProperties.$setUserProperties = setUserProperties;

app.use(createPinia());
app.use(router);
app.use(ToastPlugin, {
  position: 'top'
});
app.use(ToastPluginRadix);

app.mount('#app');


// Auth check when the page is visible
const authStore = useAuthStore()

let authCheckInterval

const checkAuthWhenVisible = () => {
  if (document.visibilityState === 'visible') {
    authStore.checkAuthStatus(router)
  }
}

const startAuthCheck = () => {
  authCheckInterval = setInterval(checkAuthWhenVisible, 5 * 60 * 1000)
}

const stopAuthCheck = () => {
  clearInterval(authCheckInterval)
}

// Start the auth check when the page becomes visible
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    checkAuthWhenVisible()
    startAuthCheck()
  } else {
    stopAuthCheck()
  }
})

// Initial start if the page is already visible
if (document.visibilityState === 'visible') {
  startAuthCheck()
}
