import axios from 'axios';

// Detect if running inside Electron
const isElectron = navigator.userAgent.toLowerCase().includes('electron');

const BASEURL = process.env.NODE_ENV === 'production' 
  ? 'https://api.sonicspec.com' 
  : 'http://127.0.0.1:5000';

axios.defaults.baseURL = BASEURL;

axios.interceptors.request.use(async (config) => {
  let token;

  if (isElectron && window.electronAPI && window.electronAPI.getAuthToken) {
    // Retrieve token from Electron's main process
    token = await window.electronAPI.getAuthToken() || '';
  } else {
    // Retrieve token from cookies in the browser
    token = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
