import { ref, type Ref, watch } from 'vue';

export type Position = 'top' | 'bottom' | 'left' | 'right' | 'bottom-left';

export function useTooltipPosition(
  tooltipRef: Ref<HTMLElement | null>,
  targetId: Ref<string>,
  position: Ref<Position> = ref('bottom')
) {
  const getPosition = () => {
    if (!targetId.value) return {}
    const target = document.getElementById(targetId.value);
    const tooltip = tooltipRef.value;
    if (!target || !tooltip) return {};

    const targetRect = target.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();
    const gap = 10;
    switch (position.value) {
      case 'top':
        return {
          bottom: `${window.innerHeight - targetRect.top + gap}px`,
          left: `${targetRect.left + targetRect.width / 2 - tooltipRect.width / 2}px`,
        };
      case 'left':
        return {
          top: `${targetRect.top + targetRect.height / 2 - tooltipRect.height / 2}px`,
          right: `${window.innerWidth - targetRect.left + gap}px`,
        };
      case 'right':
        return {
          top: `${targetRect.top + targetRect.height / 2 - tooltipRect.height / 2}px`,
          left: `${targetRect.right + gap}px`,
        };
      case 'bottom-left':
        return {
          top: `${targetRect.bottom + window.scrollY + gap}px`,
          left: `${targetRect.left}px`,
        };
      case 'bottom':
      default:
        return {
          top: `${targetRect.bottom + window.scrollY + gap}px`,
          left: `${targetRect.left + targetRect.width / 2 - tooltipRect.width / 2}px`,
        };
    }
  };

  const positionStyle = ref({});

  watch([tooltipRef, targetId], () => {
    positionStyle.value = getPosition();
  }, { immediate: true });

  return { positionStyle };
}
