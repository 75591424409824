<template>
<div v-if="!isElectron" class="flex flex-col items-center gap-x-1 hover:bg-pureWhite p-1 group gap-y-1">
    <span class="text-xs font-semibold text-white group-hover:text-SonicSpecBrandColor">Download SonicSpec</span>
    <button @click="handleDownload" ref="downloadButton" class="flex items-center gap-x-1 text-sm font-semibold text-gray-200 hover:bg-blue-500 hover:text-white rounded p-1 bg-gray-800">
    <ArrowDownTrayIcon class="h-4 w-4" />
        Download for {{ platform }}
    </button>
</div>
</template>
  
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline';
import { trackEvent } from '@/services/analytics.service';
import confetti from 'canvas-confetti';


const isElectron = navigator.userAgent.toLowerCase().includes('electron');
const downloadButton = ref<HTMLButtonElement | null>(null);

const platform = ref('');

onMounted(() => {
// Detect user's platform
    if (navigator.platform.indexOf('Win') > -1) platform.value = 'Windows';
    else if (navigator.platform.indexOf('Mac') > -1) platform.value = 'macOS';
    else platform.value = 'Linux';
});

const handleDownload = () => {
    if (downloadButton.value) {
        const rect = downloadButton.value.getBoundingClientRect();
        const x = (rect.left + rect.width / 2) / window.innerWidth;
        const y = (rect.top + rect.height / 2) / window.innerHeight;

        confetti({
            particleCount: 100,
            spread: 70,
            origin: { x, y }
        });
  }

  downloadApp();
};

const downloadApp = () => {
    let downloadUrl = '';
    switch (platform.value) {
        case 'Windows':
        downloadUrl = 'https://sonicspec-public-assets.s3.us-east-2.amazonaws.com/desktop-apps/SonicSpec+Setup+0.0.0.exe';
        break;
        case 'macOS':
        downloadUrl = 'https://sonicspec-public-assets.s3.us-east-2.amazonaws.com/desktop-apps/SonicSpec-0.0.0-arm64.dmg';
        break;
        case 'Linux':
        downloadUrl = 'https://sonicspec-public-assets.s3.us-east-2.amazonaws.com/desktop-apps/SonicSpec-0.0.0.AppImage';
        break;
    }
    trackEvent('Download SonicSpec', { platform: platform.value });
    window.location.href = downloadUrl;
};

</script>