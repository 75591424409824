<template>
        <img class="h-16" src="../../assets/sidebarImages/stackShared.svg" alt="SonicSpec" />
</template>

<script>
export default {
        name: 'stackShared',
        data() {
                return {
                        orgId: sessionStorage.getItem('orgId'),
                };
        },
};
</script>
