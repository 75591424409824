<template>
  <IconBridge
    v-if="orgId == '3ddfcf99-c8b1-41fe-9c85-ec42c455a451'"
    class="h-6"
  />
  <img
    v-else
    class="h-10 w-auto ml-[-10px]"
    src="../../assets/sideLogo.svg"
    alt="SonicSpec"
  />
</template>

<script>
import IconBridge from '@/components/icons/taskBridge/IconBridge.vue';
export default {
  name: 'LogoIconFactory',
  components: {
    IconBridge,
  },
  data() {
    return {
      orgId: sessionStorage.getItem('orgId'),
    };
  },
};
</script>
