import { defineStore } from 'pinia';
import { Router } from 'vue-router';
import { resetAnalytics, trackEvent } from '@/services/analytics.service';
// @ts-ignore
import { stytch } from '@/stytch';
import Cookies from 'js-cookie';

interface State {
  isAuthenticated: boolean;
  isLoggedOut: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => {
    return {
      isAuthenticated: false,
      isLoggedOut: false,
    };
  },
  actions: {
    setIsAuthenticated(isAuthenticated: boolean) {
      this.isAuthenticated = isAuthenticated;
    },
    checkAuthStatus(router: Router) {
      const authToken = Cookies.get('auth_token');
      this.isAuthenticated = Boolean(stytch.session.getSync()) || Boolean(authToken); 
      if (!this.isAuthenticated && !this.isLoggedOut && !router.currentRoute.value.path.includes('login')) {
        router.push({ name: 'login' });
        this.logout(router);
      }
      return this.isAuthenticated;
    },
    logout(router: Router) {
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
      trackEvent('Logged out');
      resetAnalytics();
      router.push({ name: 'login' });
      try {
        stytch.session.revoke();
      } catch (error) {
        console.error(error);
      }
      sessionStorage.clear();
      this.isAuthenticated = false;
      this.isLoggedOut = true;
    }, 
    setIsLoggedOut(isLoggedOut: boolean) {
      this.isLoggedOut = isLoggedOut;
    }
  },
});