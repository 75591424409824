import { ref, reactive } from 'vue';

export interface ToastOptions {
  text: string;
  duration?: number;
  actionLabel?: string;
  actionHandler?: () => void; // Add this line
}

export function createToastSystem() {
  const visible = ref(false);
  const toast = ref<ToastOptions>({
    text: '',
    duration: 3000,
    actionLabel: undefined,
    actionHandler: undefined,
  });

  let timeoutId: number | undefined;

  const showToast = (options: ToastOptions) => {
    toast.value = options;
    visible.value = true;

    if (timeoutId) clearTimeout(timeoutId);
    if (options.duration !== 0) {
      timeoutId = window.setTimeout(() => {
        hideToast();
      }, options.duration || 3000);
    }
  };

  const hideToast = () => {
    visible.value = false;
  };

  const handleAction = () => {
    if (toast.value.actionHandler) {
      toast.value.actionHandler();
    } else {
      console.log('Toast action triggered');
    }
    hideToast();
  };

  return {
    visible,
    toast,
    showToast,
    hideToast,
    handleAction,
  };
}

export type ToastSystem = ReturnType<typeof createToastSystem>;
