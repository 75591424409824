<template>
  <div>
    <div class="fixed left-80 top-0 z-[9999]">
      <button
        @click="toggleDropdown"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
        class="relative flex items-center gap-2 truncate rounded-b-lg bg-SonicSpecBrandColor px-3 py-2 pb-1 pt-3 text-xs text-white opacity-50 transition-all hover:translate-y-0 hover:bg-blue-800 hover:text-pureWhite hover:opacity-100 hover:shadow-md"
        :class="{ '-translate-y-3': !isOpen }"
        aria-haspopup="true"
        :aria-expanded="isOpen"
      >
        <ChatBubbleLeftRightIcon class="h-4 w-4" />
        Feedback
        <ChevronDownIcon 
          class="h-3 w-3 transition-transform duration-200" 
          :class="{ 'rotate-180': isOpen }"
        />
      </button>

      <!-- Tooltip -->
      <div
        v-if="showTooltip && !isOpen"
        class="absolute -bottom-8 left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white"
      >
        Share your thoughts with us!
      </div>

      <!-- Dropdown Menu -->
      <TransitionRoot appear :show="isOpen" as="template">
        <div 
          class="dropdown-content rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          role="menu"
        >
          <TransitionChild
            enter="transition ease-out duration-200"
            enter-from="opacity-0 translate-y-1"
            enter-to="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-1"
          >
            <div class="py-1">
              <a 
                v-for="(item, index) in menuItems" 
                :key="index"
                :href="item.href"
                target="_blank"
                class="group flex items-center gap-3 px-4 py-2 hover:bg-gray-50"
                :class="{'border-t border-gray-100': item.divider}"
                role="menuitem"
              >
                <component 
                  :is="item.icon" 
                  class="h-5 w-5 text-gray-400 transition-colors group-hover:text-SonicSpecBrandColor" 
                />
                <div>
                  <span class="block text-sm text-gray-700 group-hover:text-gray-900">
                    {{ item.label }}
                  </span>
                  <span class="block text-xs text-gray-500">
                    {{ item.description }}
                  </span>
                </div>
              </a>
            </div>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </div>
    <div 
      v-if="isOpen" 
      class="overlay" 
      @click="toggleDropdown"
      aria-hidden="true"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  LightBulbIcon,
  MapIcon,
  PlayCircleIcon
} from '@heroicons/vue/24/outline';

const isOpen = ref(false);
const showTooltip = ref(false);

const menuItems = [
  {
    icon: ClipboardDocumentCheckIcon,
    label: 'Feedback Survey',
    description: 'Help us improve your experience',
    href: 'https://dynnxzme1vx.typeform.com/to/poR9wjjW'
  },
  {
    icon: ExclamationTriangleIcon,
    label: 'Report an Issue',
    description: "Let us know if something's not working",
    href: 'https://dynnxzme1vx.typeform.com/to/m5XnCaQy'
  },
  {
    icon: LightBulbIcon,
    label: 'Request a Feature',
    description: 'Share your ideas for new features',
    href: 'mailto:phat@sonicspec.com,mike@sonicspec.com?subject=[Feedback]%20Request Feature',
    divider: true
  },
  {
    icon: MapIcon,
    label: 'Feedback on Roadmap',
    description: 'See what\'s coming next',
    href: 'https://sonicspec.productlane.com/roadmap'
  },
  {
    icon: PlayCircleIcon,
    label: 'Product Demo',
    description: 'Watch how to use our product',
    href: 'https://sonicspec.navattic.com/0d10p43'
  }
];

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped>
.dropdown-content {
  position: absolute;
  top: 32px;
  right: 0;
  min-width: 280px;
  z-index: 1;
  transform-origin: top right;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9998;
  display: block;
}

/* Prevent focus outline on click while keeping it for keyboard navigation */
button:focus:not(:focus-visible) {
  outline: none;
}

button:focus-visible {
  outline: 2px solid #4f46e5;
  outline-offset: 2px;
}
</style>
