import { io } from 'socket.io-client';
import axios from 'axios';

export const socket = io(axios.defaults.baseURL || '');

socket.on('connect', () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Socket connected with ID:', socket.id);
    console.log('Transport type:', socket.io.engine.transport.name);
    
    // Debug socket state
    console.log('Socket state:', {
      connected: socket.connected,
      disconnected: socket.disconnected,
      receiveBuffer: socket.receiveBuffer,
      sendBuffer: socket.sendBuffer
    });
  }
});

socket.on('disconnect', (reason) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Socket disconnected, reason:', reason);
  }
});

socket.on('connect_error', (error) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('Syncing error:', error);
  }
}); 
