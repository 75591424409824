import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', () => {
  const count = ref(0);
  const doubleCount = computed(() => count.value * 2);
  function increment() {
    count.value++;
  }

  return { count, doubleCount, increment };
});

export const loadingStore = defineStore('loading', () => {
  const isLoading = ref(false);

  function setLoading(value: boolean) {
    isLoading.value = value;
  }

  function toggleLoading() {
    isLoading.value = !isLoading.value;
  }

  return { isLoading, setLoading, toggleLoading };
});

export const projectCountStore = defineStore('projectCount', () => {
  const projectCount = ref(false);

  function setProjectCount(value: any) {
    projectCount.value = value;
  }

  return { projectCount, setProjectCount };
});

export const projectSelected = defineStore('projectSelected', () => {
  const projectSelect = ref(null);

  function setProjectSelected(value: any) {
    projectSelect.value = value;
  }

  return { projectSelect, setProjectSelected };
});

export const projectSpecifier = defineStore('projectSpecifier', () => {
  const projectSpec = ref(null);

  function setProjectSpec(value: any) {
    projectSpec.value = value;
  }

  return { projectSpec, setProjectSpec };
});
