import { inject, provide } from 'vue';
import type { Position } from './useTooltipPosition';

interface TooltipMethods {
  open: (
    id: string,
    description: string,
    title: string,
    position?: Position
  ) => void;
  close: () => void;
}

const TooltipSymbol = Symbol();

export function provideTooltip(methods: TooltipMethods) {
  provide(TooltipSymbol, methods);
}

export function useTooltip() {
  const tooltip = inject<TooltipMethods>(TooltipSymbol);

  if (!tooltip) {
    throw new Error(
      'Tooltip not provided. Make sure to call provideTooltip in a parent component'
    );
  }

  return tooltip;
}
