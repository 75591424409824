<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 571.15 185.34"
  >
    <path
      class="cls-1"
      d="m18.66,16.37v38.3c63.04,0,114.31,51.26,114.31,114.31h38.3c0-63.05,51.26-114.31,114.3-114.31s114.31,51.26,114.31,114.31h38.3c0-63.05,51.26-114.31,114.31-114.31V16.37H18.66Z"
    />
  </svg>
</template>
